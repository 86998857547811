.top-nav {
  position: absolute;
  top: 57px;
  width: 100%;
  z-index: 1;
  font-family: Barlow, sans-serif;
}
@media (max-width: 1300px) {
  .top-nav {
    padding: 0 30px;
  }
}
@media (max-width: 767.98px) {
  .top-nav {
    left: 15px;
    padding: 0px;
  }
}
.top-nav ul {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-nav ul > li {
  padding: 0;
  text-indent: 0;
}
.top-nav ul li {
  display: inline-block;
  overflow: hidden;
}
.top-nav ul li a {
  margin: 0 15px;
  color: #ffffff;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.17647;
  letter-spacing: 0.89px;
  text-align: right;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}
.top-nav ul li a:after {
  content: '';
  position: absolute;
  top: 48%;
  left: -5%;
  height: 2px;
  width: 0;
  -webkit-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  background-color: white;
}
.top-nav ul li a:hover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}
.top-nav ul li a:hover:after {
  width: 110%;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .top-nav ul li a:hover:after {
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
  }
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .top-nav ul li a:hover:after {
    width: 110%;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
  }
}
.top-nav ul li.current-page-ancestor a:after,
.top-nav ul li.current_page_item a:after {
  width: 110%;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.top-nav__logo img {
  width: 170px;
}
.top-nav--black svg path,
.top-nav--black svg polygon {
  fill: #000;
  -webkit-mask: none;
  mask: none;
}
.top-nav--black ul li a {
  color: #000;
}
.top-nav--black ul li a:after {
  background-color: #000;
}
@media (max-width: 999px) {
  .top-nav--black ul li a {
    color: #fff;
  }
  .top-nav--black ul li a:after {
    background-color: #fff;
  }
}
.top-nav--black ul li.current_page_item a:after {
  width: 110%;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.menu-wrap__open {
  z-index: 9999;
}

.menu__open__icon {
  height: 50px;
  width: 51px;
  display: flex;
  background-color: black;
  z-index: 9999;
}

@media (min-width: 999px) {
  .menu__open__icon {
    display: none;
    z-index: 9999;
  }
}

.hamburger__inner {
  z-index: 2;
  position: relative;
  top: 50%;
  left: 20px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 2px;
  background-color: white;
}

.hamburger__inner:before {
  content: '';
  position: absolute;
  top: -7px;
  left: 0px;
  width: 25px;
  height: 2px;
  background-color: white;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.hamburger__inner:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 0px;
  width: 20px;
  height: 2px;
  background-color: white;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.hamburger__inner.open-menu {
  z-index: 2;
  background-color: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.hamburger__inner.open-menu:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0px;
  width: 25px;
}

.hamburger__inner.open-menu:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
}

.menu-main-container {
  padding-top: 40px;
  z-index: 1;
}
@media (max-width: 999px) {
  .menu-main-menu-container {
    position: fixed;
    -webkit-transform: translate(50vw);
    -ms-transform: translate(50vw);
    transform: translate(50vw);
    top: 0;
    right: 0;
    width: 50vw;
    height: 100vh;
    background-color: #000;
    -webkit-transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
    -o-transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
    transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
  }
  .menu-main-menu-container ul {
    left: 50px;
    right: unset;
  }
  .menu-main-menu-container ul li {
    display: block;
    text-align: left;
    margin-bottom: 15px;
  }
  .menu-main-menu-container.menu-open {
    z-index: 1;
    -webkit-transform: translate(0px);
    -ms-transform: translate(0px);
    transform: translate(0px);
    -webkit-transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
    -o-transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
    transition: 0.7s cubic-bezier(0.485, 0.135, 0, 0.995);
  }
}
@media (max-width: 767.98px) {
  .menu-main-menu-container {
    z-index: 1;
    -webkit-transform: translate(100vw);
    -ms-transform: translate(100vw);
    transform: translate(100vw);
    width: 100vw;
  }
}
