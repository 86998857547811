$cAccent: #3978ef;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);
$cyon: #63bedd;

body {
  margin: 0;
}
.wrapper {
  margin: 25px 0 30px 60px;
}

.accordion-item__groupIcon {
  max-width: 21px;
}

@media (max-width: 599px) {
  .wrapper {
    margin: 0px 0 30px 0px;
  }
  div.accordion-item__location-type {
    padding-top: 10px;
    font-size: 15px;
  }

  span.accordion-item__job-title {
    margin: 0;
    display: block;
  }
  span.accordion-item__job-title a {
    color: #000000;
  }

  span.accordion-item__location {
    margin: 0;
    display: inline !important;
  }
  span.accordion-item__location a {
    color: #6d6d6d !important;
  }

  .accordion-item__work-type {
    float: right;
  }
  div.accordion-item__inner {
    padding-left: 5px;
  }
  .accordion-item__groupIcon {
    display: none;
  }
  div.accordion-item__line {
    padding: 1.4rem 0.5rem;
  }
  div.accordion-item__job-item {
    padding: 1.4rem 0.5rem;
    display: block;
  }
  h3.accordion-item__title {
    margin: 0;
  }
}

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;

  &__item:hover {
    cursor: pointer;
    // & + & {
    //   border-top: 1px solid $cLightGray;
    // }
  }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.1s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.1s;
      transition-property: opacity, transform;
    }

    #{ $self }__line {
      border-bottom: 1px solid $cyon;
    }

    #{ $self }__title {
      color: black !important;
    }

    #{ $self }__vacancies {
      color: black !important;
    }

    &__icon {
      opacity: 1 !important;
      color: black !important;
    }
  }

  &__line {
    display: block;
    padding: 1.4rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid $cLightGray;

    div:first-child {
      flex: 1;
    }
  }

  &__line div {
    display: inherit;
  }

  &__title {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #6d6d6d;
    margin: 0 0 0 20px;
  }

  &__vacancies {
    color: #6d6d6d;
    margin: 0 15px 0 0;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: 0.6;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.3s;
    transition-property: max-height;
    position: relative;
    padding: 0 0 0 40px;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.3s;
    // padding: 0 1.2rem 1.2rem;
  }

  &__job-item {
    display: block;
    padding: 1.4rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid $cLightGray;
    color: #6d6d6d;
  }

  &__job-item div {
    display: inherit;
  }

  &__work-type {
    margin: 0 35px 0 0;
    width: 100px;
    text-align: right;
  }

  &__location {
    margin: 0 150px 0 0;
  }
  &__location a {
    text-decoration: none;
    color: #6d6d6d;
  }

  &__location a:hover {
    text-decoration: underline;
  }

  &__job-title {
    margin: 0 150px 0 0;
  }
  &__job-title a {
    text-decoration: none;
    color: #6d6d6d;
  }

  &__job-title a:hover {
    text-decoration: underline;
  }
}
