@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Medium.ttf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-SemiBold.ttf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Medium.ttf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Bold.ttf') format('opentype');
  font-weight: bold;
}

html,
body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
